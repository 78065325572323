import { storyblokEditable } from "@storyblok/react";

import ImagesMatrix from "@components/molecules/ImagesMatrix";
import { ImagesMatrixItem } from "@components/molecules/ImagesMatrix/types";

import { ImagesMatrixStoryblokProps } from "./types";

const ImagesMatrixStoryblok: React.FC<ImagesMatrixStoryblokProps> = ({
  blok
}) => {
  const { images, backgroundImage } = blok;

  const items = images.map<ImagesMatrixItem>(
    ({ id, filename: src, alt }) => ({
      key: id.toString(),
      src,
      alt: alt || ""
    })
  );

  return images.length ? (
    <ImagesMatrix
      backgroundImage={backgroundImage?.filename}
      {...{ items, ...storyblokEditable(blok) }}
    />
  ) : null;
};

export default ImagesMatrixStoryblok;
