import styled from "styled-components";
import media from "@pagepro-monorepo/ui/lib/theme/config/media";

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  pointer-events: none;

  @media ${media.maxTablet} {
    display: none;
  }
`;

export const BackgroundImageWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
`;

export const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

export const ListItem = styled.li`
  position: absolute;

  &:nth-of-type(1) {
    width: 11.25rem;
    height: 11.25rem;
    left: -2.5rem;
    top: 60%;
  }
  &:nth-of-type(2) {
    width: 11.25rem;
    height: 11.25rem;
    left: 20%;
    top: 50%;
  }
  &:nth-of-type(3) {
    width: 28rem;
    height: 28rem;
    left: 50%;
    top: 60%;
    transform: translate(-50%, -50%);
  }
  &:nth-of-type(4) {
    width: 16.25rem;
    height: 16.25rem;
    left: 65%;
    top: 65%;
    transform: translate(-50%, -50%);
  }
  &:nth-of-type(5) {
    width: 11.25rem;
    height: 11.25rem;
    top: 30%;
    right: -1.75rem;
  }
  &:nth-of-type(n + 6) {
    display: none;
  }
`;
